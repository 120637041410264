<template>
    <div>
        <articleStyle :title="'情感资讯'" :number="9" />
    </div>
</template>

<script>
// import Footer from "@/components/layout/footer";
// import Header from "@/components/layout/header";
import articleStyle from "@/components/articleSyle/index";
// import Nlh from "@/components/layout/nlh";
import local from "@/api/common/local.js";
export default {
    metaInfo: {
        title: "也在网-情感护航：婚恋心理咨询专区",
        meta: [
            { charset: "utf-8" },
            {
                name: "viewport",
                content:
                    "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
            },
            {
                name: "keywords",
                content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
            }, {
                name: "description",
                content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
            }
        ]
    },
    data() {
        return {
            iSheader: true,
        };
    },
    components: {
        // Footer,
        // Header,
        // Nlh,
        articleStyle
    },
    created() {
        let Data = local.get("access_token");
        if (Data) {
            this.iSheader = true;
        } else {
            this.iSheader = false;
        }
    },
};
</script>

<style lang="less" scoped>
// .emotionalcounsel {
//     width: 100%;
//     padding-top: 80px;
//     // height: 1350px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     min-width: 1200px;
// }
</style>